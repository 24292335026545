<script setup>
const props = defineProps({
    field: {
        type: Object,
        default: () => {},
    },

    namespace: {
        type: Array,
        default: () => [],
    },

    namespaceSuffix: {
        type: Array,
        default: () => [],
    },
});

const { attrs, getId } = useFormieField(props.field, props.namespace, props.namespaceSuffix);

</script>

<template>
    <div class="group col-span-2">
        <FormieLabel
            :id="getId()"
            :field="field"
            position="above-input"
        />

        <FormieInstructions :field="field" position="above-input" />

        <input
            v-bind="attrs()"
            :min="field.min"
            :max="field.max"
            type="email"
            autocomplete="email"
            class="peer w-full border-b bg-lune px-4 pb-3 pt-7 text-base text-charbon outline-none placeholder:text-charbon focus:border-carmin"
        >

        <FormieInstructions :field="field" position="below-input" />

        <FormieLabel
            :id="getId()"
            :field="field"
            position="below-input"
        />
    </div>
</template>
